.wrapper {
  margin: 0 20px 0 20px;
  padding-top: 100px;
  text-align: left;
  background: #000;

  p {
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 6%;
    margin: 10px;
  }
}
