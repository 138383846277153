.main_content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #000;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 48px;
    font-family: Rockwell;
    font-style: normal;
    text-align: center;
    align-items: center;

    .title {
      font-size: 56px;
      font-weight: 400;
      line-height: 117%; /* 65.52px */
      letter-spacing: 3.36px;
    }

    span {
      width: 93px;
      height: 4px;
      background: #fff;
    }

    .subtitle {
      font-size: 48px;
      // font-weight: 700;
      line-height: 117%; /* 56.16px */
      letter-spacing: 2.88px;
    }
  }
}
