.wallet_connect {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  background: #000;

  .text {
    display: flex;
    padding: 24px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: #fff;

    color: #000;
    font-family: Rockwell;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 35.2px */

    cursor: pointer;
  }
}
