.menu_item_title {
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.4);

  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 130px;
  height: 34px;
  margin: 20px 16px;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  svg {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    height: 23px;

    filter: invert(1);
  }

  color: #000;
  font-family: Rockwell;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 117%; /* 18.72px */
  letter-spacing: 0.96px;
}

.titleHeader {
  top: 20px;
  left: 5%;
  margin: 0;
  width: 90vw;
  position: fixed;
  height: 50px;
  // background-color: blue;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleWrapper {
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.4);
    width: 150px;
    height: 28px;
    // margin: 20px 16px;
    padding: 5px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    svg {
      position: relative;
      top: 0;
      left: 0;
      width: auto;
      height: 23px;

      filter: invert(1);
    }

    color: #000;
    font-family: Rockwell;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 18.72px */
    letter-spacing: 0.96px;
  }
}
