.main_wrapper {
  width: 100vw;
  min-height: 100vh;
  min-height: 100dvh;
  height: 1px;
  color: #fff;
  position: relative;
  // perspective: 1000px;
}

.main {
  width: 100vw;
  min-height: 100vh;
  min-height: 100dvh;
  height: 1px;
  color: #fff;
  position: relative;
  transform-origin: 0 -20vw;
  background: #000;
}
