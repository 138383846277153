.menu_item {
  width: 100%;
  height: 100%;
}

.menu_icon {
  // position: fixed;
  // top: 16px;
  // left: 16px;
  z-index: 100;
  &.black {
    path {
      fill: #000;
    }
  }
}

.menu_itme_title {
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.4);

  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  margin: 20px 16px;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  svg {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    height: 23px;

    filter: invert(1);
  }

  color: #000;
  font-family: Rockwell;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 117%; /* 18.72px */
  letter-spacing: 0.96px;
}

.project {
  width: 100%;
  height: 100vh;
  overflow: scroll;

  .project_content_1 {
    width: 100%;
    background: #fff;
    // position: fixed;

    .container {
      padding: 80px 0 30px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      justify-content: center;
      padding-left: calc(50% - 70px);

      .line_container {
        display: flex;
        align-items: center;
        gap: 20px;

        .icon_container {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          color: #000;
          /* H1/Regular */
          font-family: Rockwell;
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 117%; /* 65.52px */
          letter-spacing: 3.36px;
          margin: 0;
        }
      }

      // .icon_container {
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   gap: 21px;
      // }

      .text_container {
        color: #000;
        text-align: left;
        /* H1/Regular */
        font-family: Rockwell;
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 117%; /* 65.52px */
        letter-spacing: 3.36px;
      }
    }
  }

  .project_content_2Kr {
    // padding-top: 100%;
    background: #000;
    margin-bottom: 110px;
    padding: 24px 24px 33px 24px;

    color: #fff;
    text-align: left;
    /* Body */
    font-family: Rockwell;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%; /* 21.28px */
  }

  .project_content_2 {
    // padding-top: 100%;
    background: #000;
    padding: 24px 24px 33px 24px;

    color: #fff;
    text-align: left;
    /* Body */
    font-family: Rockwell;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%; /* 21.28px */
  }

  .card_content {
    background: #fff;
    padding: 80px 16px 55px;
    height: calc(100% - 135px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 12px;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: auto;
    }

    .active {
      transition: filter 0.4s ease-out;
      filter: invert(1);
    }

    @media (max-height: 630px) {
      svg {
        width: auto;
        height: 100%;
      }
    }

    svg:nth-child(1) {
      justify-self: flex-end;
      align-self: flex-end;
    }

    svg:nth-child(2) {
      justify-self: flex-start;
      align-self: flex-end;
    }

    svg:nth-child(3) {
      justify-self: flex-end;
      align-self: flex-start;
    }

    svg:nth-child(4) {
      justify-self: flex-start;
      align-self: flex-start;
    }

    .card {
      height: fit-content;
      border-radius: 13px;
      border: 3px solid #000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      svg {
        height: auto;
      }

      .top_bar {
        padding: 13px 13px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        color: #000;
        font-family: Rockwell;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 117%; /* 14.04px */
        letter-spacing: 0.72px;
      }

      .center {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;

        color: #000;
        text-align: center;
        font-family: Rockwell;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 117%; /* 21.06px */
        letter-spacing: 1.08px;
        text-transform: uppercase;
      }

      .bottom_bar {
        padding: 13px 13px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        color: #000;
        font-family: Rockwell;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 117%; /* 14.04px */
        letter-spacing: 0.72px;
      }

      transition: all 0.4s ease-out;

      &:hover {
        background: #000;

        path {
          transition: inherit;
          fill: #fff;
        }

        .top_bar {
          color: #fff;
        }

        .center {
          color: #fff;
        }

        .bottom_bar {
          color: #fff;
        }
      }
    }
  }

  .card_family_title {
    background: #000;
    padding: 100px 16px 0 16px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 7px;

    img {
      width: 100%;
      height: auto;
      border-radius: 3px;
      overflow: hidden;
    }

    .front_card {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      justify-content: center;
      border: 1px solid #fff;
      border-radius: 5px;

      color: #fff;
      font-family: Rockwell;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 117%; /* 14.04px */
      letter-spacing: 0.48px;
      text-transform: uppercase;

      svg {
        height: auto;
        path {
          fill: #fff;
        }
      }
    }
  }

  .card_family_deck {
    background: #000;
    padding: 30px 16px 0 16px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 7px;
    position: relative;

    div {
      position: relative;
      width: 100%;
    }

    img {
      display: block;
      perspective: 400px;
      width: 100%;
      height: auto;
      border-radius: 3px;
      overflow: hidden;
    }

    .mycard_wrapper {
      perspective: 400px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        border-radius: 3px;
        overflow: hidden;
      }

      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: cardglow 2s 1s infinite alternate;
      }
    }
  }

  .another_card {
    padding: 67px;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);

    .card_button_wrapper {
      position: relative;
      padding-bottom: 100%;
    }

    .card_button {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      border: 1px solid #fff;
      border-radius: 5px;

      svg {
        height: auto;

        path {
          fill: #fff;
        }
      }
    }
  }

  .footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #000;

    .icon_container {
      padding: 10px 24px;
      // margin-bottom: 72px;
      display: flex;
      justify-content: space-between;
    }

    &.prev_icon {
      background: #fff;
    }
  }
}

.my_nft {
  margin-top: 80px;
  position: relative;
  width: 100%;
  height: calc(100% - 80px);
  background: #000;

  .content1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .image_wrapper {
      margin: auto;
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      div {
        height: 80%;
        position: relative;
      }

      .title {
        color: #fff;
        font-family: Rockwell;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      img {
        display: block;
        width: auto;
        height: 100%;
        border-radius: 6px;
        overflow: hidden;
      }

      .card_shadow {
        position: absolute;
        border-radius: 6px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: mycardglow 2s infinite alternate ease-in-out;
      }

      .card_halo {
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        transform: translate(-50%, -50%);
        animation: cardhalo 2s infinite alternate ease-in-out;
      }
    }

    .text_wrapper {
      height: 50%;

      .deck {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: min(35px, 6vh);

        .line {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 38px;

          color: #fff;
          font-family: Numbers Deuce;
          font-size: min(48px, 8vh);
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .content2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;

    .image_wrapper {
      position: relative;
      width: 100%;
      width: 100%;
      height: 50%;

      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .text_wrapper {
      position: relative;
      padding: 16px 60px 0 60px;
      color: rgba(255, 255, 255, 0.95);
      text-align: center;
      font-family: Rockwell;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 33.6px */
    }
  }
}

@keyframes cardglow {
  from {
    box-shadow: 0px 0px 10px 0px rgba(238, 190, 14, 0.3) inset,
      0px 0px 10px 0px rgba(238, 190, 14, 0.3);
  }
  to {
    box-shadow: 0px 0px 10px 0px rgba(238, 190, 14, 1) inset,
      0px 0px 10px 0px rgba(238, 190, 14, 1);
  }
}

@keyframes mycardglow {
  from {
    box-shadow: 0px 0px 10px 0px rgba(238, 190, 14, 1) inset,
      0px 0px 47px 0px rgba(238, 190, 14, 0.6);
  }
  to {
    box-shadow: 0px 0px 10px 0px rgba(238, 190, 14, 0.3) inset,
      0px 0px 10px 0px rgba(238, 190, 14, 0.3);
  }
}

@keyframes cardhalo {
  from {
    box-shadow: 0px 0px 80px 60px rgba(238, 190, 14, 1);
  }
  to {
    box-shadow: 0px 0px 80px 60px rgba(238, 190, 14, 0.3);
  }
}

.nextIconWrapper {
  position: fixed;
  bottom: 50px;
  right: 20px;
}
