.menu {
  width: 100%;
  height: 100%;
  background: #000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .menu_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;

    .menu_item {
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      position: relative;

      .menu_name {
        width: 100%;
        text-align: center;
        color: #fff;
        font-family: Rockwell;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 117%; /* 46.8px */
        letter-spacing: 2.4px;
      }

      .icon_container {
        width: 30px;
        height: 30px;

        svg {
          height: 100%;
        }
      }

      .menu_highlight {
        position: absolute;
        bottom: -20px;
        left: 0;
        overflow: hidden;

        svg {
          transition: all 0.3s ease-out;
          transform: translateX(-100%);
        }
      }

      &.active {
        .menu_highlight {
          svg {
            transform: translateX(0);
          }
        }
      }
    }
  }
}
