.wrapper {
  height: 100vh;
  width: 100vw;

  // background-color: yellow;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .title {
    font-size: 56px;
    font-style: italic;
  }

  .author {
    font-size: 56px;
    margin-bottom: 48px;
  }

  .description {
    max-width: 720px;
    font-size: 16px;
    margin-bottom: 48px;
    opacity: 0.5;
  }

  .footer {
    width: 100vw;
    position: absolute;
    bottom: 20px;
    left: 0;
    font-size: 14px;
  }
}
