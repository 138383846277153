.pageWrapper {
  // height: 100vh;
  background: #000;

  .picture {
    img {
      width: 100%;
    }

    // margin-bottom: 40px;
  }

  .titleWrapper {
    // background-color: white;
    // position: fixed;
    // top: calc(66.666vw - 42px);
    height: 84px;
    width: 92%;
    // color: black;
    text-align: right;

    .title {
      font-size: 26px;
      margin: 10px 20px 0 0;
    }

    .subtitle {
      font-size: 18px;
      margin: 0 20px 0 0;
    }
  }

  .caption {
    // position: fixed;
    text-align: left;
    color: #8a8a8a;
    font-size: 12px;
    margin: 0 20px 0 20px;
    // top: calc(66.666vw + 42px + 10px);
  }

  .description_wrapper {
    padding: 0 20px;
    // padding-top: calc(66.666vw + 42px + 50px);
    // height: calc(100vh - (66.666vw + 42px + 50px));
  }

  .description {
    background: #000;
    text-align: left;
    overflow: scroll;
    // width: 100%;
    height: 100%;
    // z-index: 1;
    padding: 0 20px;
    // padding-top: calc(66.666vw + 42px + 50px);

    p {
      font-size: 16px;
      line-height: 133%;
    }
  }

  .descriptionKr {
    background: #000;
    text-align: left;
    overflow: scroll;
    // width: 100%;
    height: 100%;
    // z-index: 1;
    padding: 0 20px;
    // padding-top: calc(66.666vw + 42px + 50px);

    p {
      font-size: 14px;
      line-height: 133%;
    }
  }

  .fadeoutTop {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(66.666vw + 42px + 240px);
    background: linear-gradient(
      180deg,
      black 65%,
      transparent 70%,
      transparent 100%
    );
  }

  .fadeoutBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, transparent 0%, black 50%);
  }

  .nextIconWrapper {
    position: fixed;
    bottom: 50px;
    right: 20px;
  }
}
