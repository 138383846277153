.languageToggleWrapper {
  position: fixed;
  bottom: 50px;
  left: 20px;
  z-index: 1;

  display: flex;

  .englishRight {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;
    background-color: white;
    border-radius: 0 8px 8px 0;
    color: black;

    border: 2px solid white;
  }

  .englishLeft {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;
    background-color: black;
    border-radius: 8px 0 0 8px;
    color: white;

    border: 2px solid white;
  }

  .koreanLeft {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;
    background-color: white;
    color: black;
    border-radius: 8px 0 0 8px;
    border: 2px solid white;
  }

  .koreanRight {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;
    background-color: black;
    border-radius: 0 8px 8px 0;
    color: white;

    border: 2px solid white;
  }
}
